import React, { useRef, useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import gsap, { Power2 } from "gsap"
import { document } from "browser-monads"

import trapFocus from "@components/utils/trap-focus"
import FadeLink from "@components/fade-link"
import Logo from "@images/svg/logo.svg"
import ArrowRight from "@images/svg/arrow-right.svg"
import SubMenu from "./sub-menu"

import "./menu-button.scss"
import "./style.scss"

const MobileMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [subMenu, setSubMenu] = useState({
    show: false,
    programs: {},
    title: "",
  })
  const menuRef = useRef()
  const openTl = gsap.timeline({
    paused: true,
    onStart: () => {
      document.body.classList.add("no-scroll")
      trapFocus(menuRef.current)
    },
  })
  const closeTl = gsap.timeline({
    paused: true,
    onComplete: () => {
      document.body.classList.remove("no-scroll")
    },
  })

  useEffect(() => {
    if (menuRef.current) {
      const links = menuRef.current.getElementsByClassName("menu-link-item")
      const button = menuRef.current.getElementsByClassName("button")[0]

      openTl.to(menuRef.current, 0.5, {
        clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
        onStart: () => {
          Array(...links).forEach(link => {
            link.style.opacity = 0
            link.style.transform = "translateX(1rem)"
          })
          button.style.transform = "translateX(1rem)"
          button.style.opacity = 0
        },
      })
      openTl.fromTo(
        [links, button],
        0.35,
        {
          opacity: 0,
          x: `1rem`,
        },
        {
          x: 0,
          opacity: 1,
          stagger: 0.1,
        },
        "-=0.25"
      )

      closeTl.fromTo(
        [links, button],
        0.35,
        {
          opacity: 1,
          x: 0,
        },
        {
          x: "-2rem",
          opacity: 0,
          stagger: 0.1,
        }
      )
      closeTl.to(
        menuRef.current,
        0.35,
        {
          clipPath: `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`,
          onComplete: () => {
            menuRef.current.style.clipPath = `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`
          },
        },
        "-=0.25"
      )
    }
  })

  useEffect(() => {
    if (menuOpen) {
      openTl.play()
    } else {
      closeTl.play()
    }
  }, [menuOpen])

  return (
    <StaticQuery
      query={graphql`
        query MobileMenuQuery {
          allWpProgramType {
            programCategories: nodes {
              databaseId
              name
              programs {
                programs: nodes {
                  menuOrder
                  programDetails {
                    pageImage {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                  }
                  title
                  uri
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { programCategories } = data.allWpProgramType

        return (
          <div className="mobile-menu-container">
            <button
              className={`menu-button ${menuOpen ? `is-active` : ``}`}
              type="button"
              onClick={() => {
                setMenuOpen(!menuOpen)
              }}
            >
              <span className="menu-button-box">
                <span className="menu-button-inner" />
              </span>
              <div className="is-sr-only">{`Toggle Mobile Menu`}</div>
            </button>

            <nav
              className={`mobile-menu ${menuOpen ? `is-active` : ``}`}
              ref={menuRef}
              role="navigation"
              tabIndex="-1"
            >
              <FadeLink
                to={`/`}
                className="logo-wrapper"
                onClick={() => {
                  setMenuOpen(!menuOpen)
                }}
              >
                <Logo
                  className={`logo`}
                  alt={`Child & Youth Milopemahtesewin Services Logo`}
                />
                <span className="is-sr-only">{`Back to Home`}</span>
              </FadeLink>
              <div className="container">
                <ul className="nav-menu">
                  <li className="menu-link-item">
                    <a
                      href={`/#about`}
                      onClick={() => {
                        setMenuOpen(!menuOpen)
                      }}
                    >
                      {`About`}
                    </a>
                  </li>
                  <li className="menu-link-item has-sub-menu">
                    <button
                      onClick={() => {
                        setSubMenu({
                          show: true,
                          programs: programCategories[0].programs,
                          title: `Programs & Services`,
                        })
                      }}
                    >
                      {`Programs & Services`}
                      <ArrowRight />
                    </button>
                  </li>
                  <li className="menu-link-item has-sub-menu">
                    <button
                      onClick={() => {
                        setSubMenu({
                          show: true,
                          programs: programCategories[1].programs,
                          title: `The Prevention House`,
                        })
                      }}
                    >
                      {`The Prevention House`}
                      <ArrowRight />
                    </button>
                  </li>
                </ul>
                <div
                  className="has-text-centered"
                  style={{ padding: `3rem 0 0` }}
                >
                  <a
                    style={{
                      fontSize: `2rem`,
                    }}
                    href={`#contact-us`}
                    className="button"
                    onClick={() => {
                      setMenuOpen(!menuOpen)
                    }}
                  >{`Contact Us`}</a>
                </div>
              </div>
              <SubMenu
                title={subMenu.title}
                programs={subMenu.programs}
                show={subMenu.show}
                setSubMenu={setSubMenu}
                mainMenuRef={menuRef}
              />
            </nav>
          </div>
        )
      }}
    />
  )
}

export default MobileMenu
