const trapFocus = element => {
  let focusableEls = element.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
  )
  let firstFocusableEl = focusableEls[0]
  let lastFocusableEl = focusableEls[focusableEls.length - 1]
  let KEYCODE_TAB = 9

  element.focus()

  element.addEventListener("keydown", e => {
    let isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB

    if (!isTabPressed) {
      return
    }

    if (e.shiftKey) {
      /* shift + tab */ if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus()
        e.preventDefault()
      }
    } /* tab */ else {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus()
        e.preventDefault()
      }
    }
  })
}

export default trapFocus
