import React, { useRef, useEffect, useState } from "react"
import gsap, { Power2 } from "gsap"

import FadeLink from "@components/fade-link"
import ArrowLeft from "@images/svg/arrow-left.svg"
import "./sub-menu.scss"

import trapFocus from "@components/utils/trap-focus"

const SubMenu = ({ programs, title, show, setSubMenu }) => {
  const menuRef = useRef()
  const ulRef = useRef()
  const openTl = gsap.timeline({
    paused: true,
  })
  const closeTl = gsap.timeline({
    paused: true,
  })

  useEffect(() => {
    if (menuRef.current) {
      const backButton =
        menuRef.current.getElementsByClassName("back-button")[0]
      const heading = menuRef.current.getElementsByClassName("cat-title")[0]
      const hr = menuRef.current.getElementsByTagName("hr")[0]
      const links = menuRef.current.getElementsByTagName("li")

      openTl.to(menuRef.current, 0.5, {
        clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
        onStart: () => {
          Array(...links).forEach(link => {
            link.style.opacity = 0
            link.style.transform = "translateX(1rem)"
          })
          heading.style.opacity = 0
          heading.style.transform = "translateX(1rem)"
          hr.style.opacity = 0
          hr.style.transform = "translateX(1rem)"
          backButton.style.opacity = 0
          backButton.style.transform = "translateX(1rem)"
        },
      })
      openTl.fromTo(
        [backButton, heading, hr, links],
        0.35,
        {
          opacity: 0,
          x: `1rem`,
        },
        {
          x: 0,
          opacity: 1,
          stagger: 0.1,
          onComplete: () => {
            ulRef.current.focus()
            trapFocus(menuRef.current)
          },
        },
        "-=0.25"
      )

      closeTl.fromTo(
        [backButton, heading, hr, links],
        0.35,
        {
          opacity: 1,
          x: 0,
        },
        {
          x: "-2rem",
          opacity: 0,
          stagger: 0.1,
        }
      )
      closeTl.to(
        menuRef.current,
        0.35,
        {
          clipPath: `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`,
          onComplete: () => {
            menuRef.current.style.clipPath = `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`
          },
        },
        "-=0.25"
      )
    }
  })

  useEffect(() => {
    if (show) {
      openTl.play()
    } else {
      closeTl.play()
    }
  }, [show])

  return (
    <div className={`sub-menu ${show ? `is-active` : ``}`} ref={menuRef}>
      <div className="sub-menu-header">
        <button
          className="back-button"
          onClick={() => {
            setSubMenu({
              show: false,
              title: title,
              programs: programs,
            })
          }}
        >
          <ArrowLeft /> <span>{`Back`}</span>
        </button>

        <span className="cat-title">{title}</span>
        <hr />
      </div>
      <ul className="sub-menu-items" ref={ulRef}>
        {programs.programs
          ? programs.programs.map((program, index) => {
              const { programDetails, title, uri } = program
              const { pageImage } = programDetails
              return (
                <li key={`program_${index}`}>
                  <FadeLink to={uri}>
                    <div className="columns is-flex">
                      <div className="column is-flex is-4-mobile">
                        <img
                          alt={pageImage.altText}
                          src={pageImage.localFile.publicURL}
                        />
                      </div>
                      <div className="column is-flex is-8-mobile">
                        <span className="title">{title}</span>
                      </div>
                    </div>
                  </FadeLink>
                </li>
              )
            })
          : null}
      </ul>
    </div>
  )
}

export default SubMenu
