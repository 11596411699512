import React, { useRef, useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import parse from "html-react-parser"
import { IoMdClose } from "react-icons/io"
import { window, exists } from "browser-monads"

import MobileMenu from "@components/mobile-menu"
import FadeLink from "@components/fade-link"
import Logo from "@images/svg/logo.svg"
import "./style.scss"

const Header = () => {
  let sessionDismiss = exists(window)
    ? sessionStorage.getItem("alert-dismissed")
    : false
  const [notificationDismissed, setNotificationDismissed] = useState(true)
  const headerRef = useRef()
  const notificationRef = useRef()

  useEffect(() => {
    if (notificationRef.current) {
      headerRef.current.style.marginTop = `${notificationRef.current.clientHeight}px`
    } else {
      headerRef.current.style.marginTop = `0`
    }
  })

  useEffect(() => {
    sessionDismiss === "true"
      ? setNotificationDismissed(true)
      : setNotificationDismissed(false)
  })

  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          wpMenu(databaseId: { eq: 25 }) {
            id
            menuItems {
              menuItems: nodes {
                id
                title
                path
                target
                url
                order
                label
                cssClasses
              }
            }
          }
          wp {
            siteOptions {
              notificationBar {
                notificationMessage
                showNotificationBar
                notificationLinkText
                notificationPageLink {
                  ... on WpPage {
                    id
                    uri
                  }
                  ... on WpPost {
                    id
                    uri
                  }
                  ... on WpProgram {
                    id
                    uri
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { menuItems } = data.wpMenu.menuItems
        const {
          notificationMessage,
          showNotificationBar,
          notificationLinkText,
          notificationPageLink,
        } = data.wp.siteOptions.notificationBar

        return (
          <header ref={headerRef}>
            <div>
              {showNotificationBar === true &&
                notificationDismissed === false ? (
                  <div className="notification-bar" ref={notificationRef}>
                    <div className="container">
                      <div className="inner">
                        <div className="notification-message">
                          {parse(notificationMessage)}
                          {notificationLinkText && notificationPageLink ? (
                            <FadeLink to={notificationPageLink.uri}>
                              {notificationLinkText}
                            </FadeLink>
                          ) : null}
                        </div>

                      <button
                        className="close"
                        onClick={() => {
                          sessionStorage.setItem("alert-dismissed", true)
                          setNotificationDismissed(true)
                        }}
                      >
                        <span className="is-sr-only">{`Dismiss alert`}</span>
                        <IoMdClose />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="container">
              <div className="columns is-gapless is-flex header-columns">
                <div className="column is-4 header-column">
                  <FadeLink to={`/`} className="logo-wrapper">
                    <Logo
                      className={`logo`}
                      alt={`Child & Youth Milopemahtesewin Services Logo`}
                    />
                    <span className="is-sr-only">{`Back to Home`}</span>
                  </FadeLink>
                </div>
                <div className="column is-8 has-text-right header-column">
                  <ul className="main-menu">
                    {menuItems.map(menuItem => {
                      const { connectedNode, cssClasses, id, url, label } =
                        menuItem
                      return connectedNode ? (
                        <li key={id} className="menu-item">
                          <FadeLink className={cssClasses.join(" ")} to={url}>
                            {label}
                          </FadeLink>
                        </li>
                      ) : (
                        <li key={id} className="menu-item">
                          <AnchorLink className={cssClasses.join(" ")} to={url}>
                            {label}
                          </AnchorLink>
                        </li>
                      )
                    })}
                  </ul>
                  <MobileMenu />
                </div>
              </div>
            </div>
          </header>
        )
      }}
    />
  )
}

export default Header
